/**
 * Api handlers for the App.
 * These will expose functions using the endpoints to call itslearning api's
 */
import { del, get, post, put } from "@itslearning/atlas/network/fetch";
import config from "./config";
import {
    endpoints,
    pageSizeInCalendar,
    planSorting,
    plansForTopicPageSize,
    defaultPageSize
} from "./constants.js";

const headers = () => ({
    headers: {
        [config.antiForgeryHeaderName]: config.antiForgeryHeaderValue
    }
});

const pageSize = defaultPageSize;

const getQueryStringPaging = (pageIndex, pageSize, childId) =>
    `&PageIndex=${pageIndex}&PageSize=${pageSize}&ChildId=${childId}`;

/**
 * Gets a dictionary containing all the counts for plans in a course (All plans, current plans, past plans, plans without date)
 *
 * @export
 * @return {Dictionary} All plan counts
 */
export const getAllPlanCounts = () =>
    get(`/restapi/personal/course/${config.courseId}/plans/counts/v1`);

/**
 * For public link, gets a dictionary containing all the counts for plans in a course (All plans, current plans, past plans, plans without date)
 *
 * @export
 * @return {Dictionary} All plan counts
 */
export const getAllPlanCountsPublic = () =>
    get(`/restapi/planner/anonymous2/${config.publicAccessKey}/counts/v1`);

/**
 * Gets plans for this week
 */
export const getPlansThisWeek = () =>
    get(`/restapi/personal/course/${config.courseId}/plans/week/v1`);

/**
 * Gets last opened plan
 */
export const getLastOpenedPlan = () =>
    get(`/restapi/personal/course/${config.courseId}/plans/lastOpened/v1`);

export const getSelfPacedPlansToWorkOn = () =>
    get(`/restapi/personal/course/${config.courseId}/plans/selfpaced/toworkon/v1`);

/**
 * Gets all plans
 *
 * @export
 * @param {number} [pageIndex=0] - Current page index
 * @return {JSON entity list} - All plans
 */
export const getAllPlans = (pageIndex = 0) =>
    get(
        `/restapi/personal/course/${config.courseId}/plans/all/v1?${getQueryStringPaging(
            pageIndex,
            pageSize,
            config.childId
        )}`
    );

/**
 * Gets all plans for public link
 *
 * @export
 * @param {number} [pageIndex=0] - Current page index
 * @return {JSON entity list} - All public plans
 */

export const getAllPlansPublic = (pageIndex = 0) =>
    get(
        `/restapi/planner/anonymous2/${config.publicAccessKey}/all/v1?&PageIndex=${pageIndex}&PageSize=${pageSize}`
    );

/**
 * Gets plans by their IDs.
 */
export const getPlansForCalendar = (courseId, childId, planIds, viewForPersonId, pageIndex = 0) =>
    get(
        `/restapi/personal/course/${courseId}/plans/selected/v1?ChildId=${childId}&planIds=${planIds.join()}&viewForPersonId=${viewForPersonId}&PageIndex=${pageIndex}&PageSize=${pageSizeInCalendar}`
    );

/**
 * Gets plans for printing by their IDs.
 */
export const getPlansForPrinting = (planIds, selectedView) =>
    get(
        `/restapi/personal/course/${
            config.courseId
        }/plans/print/v1?planIds=${planIds.join()}&selectedView=${selectedView}`
    );

/**
 * Gets plans for printing by their IDs.
 */
export const getPlansForPrintingTable = planIds =>
    get(
        `/restapi/personal/course/${config.courseId}/plans/print/table/v1?planIds=${planIds.join()}`
    );

/**
 * Gets topics and their plans. Only single topic ID can be passed if topicIds is not null.
 */
export function getTopicsWithPlansForPrinting(topicIds, planIds) {
    const planIdsParameter = planIds && planIds.length ? `&planIds=${planIds.join()}` : "";

    return get(
        `/restapi/personal/course/${config.courseId}/plans/print/topics/v1?topicIds=${topicIds}${planIdsParameter}`
    );
}

/**
 * Gets plans that are ongoing or haven't started yet
 */
export const getCurrentPlans = (pageIndex = 0) =>
    get(
        `/restapi/personal/course/${config.courseId}/plans/current/v1?${getQueryStringPaging(
            pageIndex,
            pageSize,
            config.childId
        )}`
    );

/**
 * Gets plans that are ongoing or haven't started yet
 */
export const getCurrentPlansPublic = (pageIndex = 0) =>
    get(
        `/restapi/planner/anonymous2/${config.publicAccessKey}/current/v1?&PageIndex=${pageIndex}&PageSize=${pageSize}`
    );

/**
 * Gets plans that have no dates
 */
export const getWithoutDatePlans = (pageIndex = 0) =>
    get(
        `/restapi/personal/course/${config.courseId}/plans/without-date/v1?${getQueryStringPaging(
            pageIndex,
            pageSize,
            config.childId
        )}`
    );

/**
 * Gets plans that have no dates
 */
export const getWithoutDatePlansPublic = (pageIndex = 0) =>
    get(
        `/restapi/planner/anonymous2/${config.publicAccessKey}/without-date/v1?&PageIndex=${pageIndex}&PageSize=${pageSize}`
    );

/**
 * Gets plans that have already finished
 */
export const getPastPlans = (pageIndex = 0) =>
    get(
        `/restapi/personal/course/${config.courseId}/plans/past/v1?${getQueryStringPaging(
            pageIndex,
            pageSize,
            config.childId
        )}`
    );

/**
 * Gets plans that have already finished
 */
export const getPastPlansPublic = (pageIndex = 0) =>
    get(
        `/restapi/planner/anonymous2/${config.publicAccessKey}/past/v1?&PageIndex=${pageIndex}&PageSize=${pageSize}`
    );

/**
 * Gets all Topics for plans
 */
export const getTopics = () =>
    get(`/restapi/personal/course/${config.courseId}/plans/topics/v1?ChildId=${config.childId}`);

/**
 * Gets all Topics for plans
 */
export const getTopicsPublic = () =>
    get(`/restapi/planner/anonymous2/${config.publicAccessKey}/topics/v1`);

/**
 * Gets a single plan by its id
 */
export const getPlan = (planId = config.planId) =>
    get(`/restapi/personal/course/${config.courseId}/plans/${planId}/v2?ChildId=${config.childId}`);

/**
 * Gets a single plan by its id
 */
export const getPlanPublic = (planId = config.planId) =>
    get(`/restapi/planner/anonymous2/${config.publicAccessKey}/${planId}/v2`);

/**
 * Gets a single topic by its id
 */
export const getTopic = (topicId = config.topic) =>
    get(
        `/restapi/personal/course/${config.courseId}/plans/topics/${topicId}/v1?ChildId=${config.childId}`
    );

/**
 * Gets a single topic by its id
 */
export const getTopicPublic = (topicId = config.topic) =>
    get(`/restapi/planner/anonymous2/${config.publicAccessKey}/topics/${topicId}/v1`);

/**
 * Gets a random colour for topic
 * Re-using endpoint for old planner
 * First parameter in post is endpoint, second data (undefined), third is options
 * There is a tech-debt for anti-forgery check (TRK-6737, or this could be a GET instead).
 */
export const getTopicRandomColour = () =>
    post(
        `/restapi/planner/topic/getRandomColorSchemeForNewTopic/${config.courseId}`,
        undefined,
        headers()
    );

export const createTopic = (title, colour) =>
    post(
        `/restapi/planner/topic/addTopic`,
        { CourseId: config.courseId, topicName: title, topicColorSchema: colour },
        headers()
    );

export const updateTopicColor = (topicId, colorId) =>
    post(
        `/restapi/planner/topic/setTopicColorSchema`,
        { CourseId: config.courseId, topicId, topicColorSchema: colorId },
        headers()
    );

export const updateTopicTitle = (topicId, title) =>
    post(
        `/restapi/planner/column/updatetopictitle/v2`,
        { courseId: config.courseId, topicId, data: title },
        headers()
    );

export const getPlansInTopic = (topicId, pageIndex = 0, planOrder = planSorting.NotSetValue) =>
    get(
        `/restapi/personal/course/${
            config.courseId
        }/plans/topics/${topicId}/plans/v1?${getQueryStringPaging(
            pageIndex,
            pageSize,
            config.childId
        )}&PlanOrder=${planOrder}`
    );

export const getPlansInTopicPublic = (
    topicId,
    pageIndex = 0,
    planOrder = planSorting.NotSetValue
) =>
    get(
        `/restapi/planner/anonymous2/${config.publicAccessKey}/topics/${topicId}/plans/v1?PageIndex=${pageIndex}&PageSize=${pageSize}&PlanOrder=${planOrder}`
    );

export const setPlanTitle = (planId, title) =>
    post(
        "/restapi/planner/column/updateplantitle/v2",
        { courseId: config.courseId, planId: planId, data: title },
        headers()
    );

export const setPlanClassHours = (planId, classHours) =>
    post(
        "/restapi/planner/column/updateplanclasshours/v2",
        { courseId: config.courseId, planId: planId, data: classHours },
        headers()
    );

export const setPlanDescription = (planId, description) =>
    post(
        "/restapi/planner/column/updateplandescription",
        {
            courseId: config.courseId,
            planId: planId,
            data: description,
            skipPlainTextDataTrimming: true
        },
        headers()
    );

export const setPlanTimeSpan = (planId, startTime, stopTime) =>
    put(
        "/restapi/planner/plandate/timespan/v2",
        {
            courseId: config.courseId,
            planId,
            showPlanDates: true,
            start: startTime,
            stop: stopTime
        },
        headers()
    );

export const setPlanWeekAndDay = (planId, startTime, stopTime) =>
    post(
        "/restapi/planner/plandate/weekandday",
        {
            CourseId: config.courseId,
            PlanId: planId,
            showPlanDates: true,
            start: startTime,
            stop: stopTime
        },
        headers()
    );

/**
 * Update the course events.
 * If array of activity ids is empty, all existing connections will be removed.
 */
export const updatePlanEvents = (planId, activityIds) =>
    post(
        `/restapi/planner/planDate/events`,
        {
            courseId: config.courseId,
            planId: planId,
            activityIds: activityIds,
            filterStartDate: null,
            filterStopDate: null,
            showPlanDates: true
        },
        headers()
    );

export const createPlan = planParams =>
    post(
        "/restapi/planner/plan/add",
        {
            TopicId: planParams.topic ? planParams.topic.id : null,
            Start: planParams.start,
            End: planParams.stop,
            CourseId: config.courseId
        },
        headers()
    );

export const startPlan = (courseId, planId) =>
    put(`/restapi/course/${courseId}/plans/${planId}/progress/start/v1`, undefined, headers());

export const completePlan = (courseId, planId) =>
    put(`/restapi/course/${courseId}/plans/${planId}/progress/completion/v1`, undefined, headers());

export const uncompletePlan = (courseId, planId) =>
    del(`/restapi/course/${courseId}/plans/${planId}/progress/completion/v1`, undefined, headers());

export const togglePlansStatus = (
    planIds,
    activate,
    toggleElementsActivation,
    isBulkAction,
    executeForAllPlans = false,
    topicIds = []
) => {
    const requestModel = {
        courseId: config.courseId,
        activate,
        toggleElementsActivation,
        isBulkAction
    };

    if (executeForAllPlans) {
        requestModel.executeForAllPlans = true;
        requestModel.topicIds = topicIds;
    } else {
        requestModel.planIds = planIds;
    }

    return put("/restapi/planner/plan/multiple/status/v2", requestModel, headers());
};

export function getLearningObjectives(nodeId, params = {}) {
    const loPageSize = 100;
    const pageIndex = 0;

    const defaultParams = {
        omitItems: false,
        omitFolders: false,
        includeUnpublished: false,
        setRootAsParent: false,
        selectableCategory: false,
        selectableLearningObjective: true,
        SelectableSubject: false,
        cutOffLoStructure: true,
        encoding: 1
    };

    params = { ...defaultParams, ...params };

    const paramString = Object.keys(params)
        .map(p => `${p}=${params[p]}`)
        .join("&");

    return get(
        `/restapi/course/learningobjective/repository/all/v1?CourseID=${config.courseId}&nodeId=${nodeId}&page=${pageIndex}&pageSize=${loPageSize}&${paramString}`
    );
}

export const setPlanLearningObjectives = (planId, learningObjectiveIds) =>
    post(
        "/restapi/planner/learningObjective/plan",
        { courseId: config.courseId, planId, learningObjectiveIds },
        headers()
    );

export const removePlanLearningObjective = (planId, learningObjectiveId) =>
    del(
        "/restapi/planner/learningObjective/plan",
        { courseId: config.courseId, planId, learningObjectiveId },
        headers()
    );

export async function setPlanImage(planId, file) {
    const formData = new FormData();

    formData.append("file", file, file.name);

    const rawResult = await fetch(
        `/restapi/planner/plan/thumbnail/${config.courseId}/${planId}/v1`,
        {
            body: formData,
            method: "post",
            mode: "cors",
            cache: "default",
            credentials: "same-origin",
            headers: { [config.antiForgeryHeaderName]: config.antiForgeryHeaderValue }
        }
    );

    return await rawResult.json();
}

export const removePlanImage = planId =>
    del(
        "/restapi/planner/plan/thumbnail/v1",
        { PlanId: planId, CourseId: config.courseId },
        headers()
    );

export const addElementsToPlan = (elementIds, planId, columnId, partialUpdate) =>
    post(
        `/restapi/planner/element/updatePlanElements`,
        { planId, courseId: config.courseId, columnId, elementIds, partialUpdate },
        headers()
    );

export async function getRecentElements(elementType) {
    const endpoint = endpoints.getRecentElements
        .replace("{courseId}", config.courseId)
        .replace("{elementType}", elementType);

    const result = await get(endpoint);

    return result.EntityArray;
}

export const getRecentCloudElements = async (cloudService, recentCloudElementsSettings) =>
    get(
        endpoints.getRecentCloudElements
            .replace("{cloudService}", cloudService)
            .replace("{filesCount}", recentCloudElementsSettings.filesCount)
            .replace("{includeSharedFiles}", recentCloudElementsSettings.includeSharedFiles)
            .replace("{includeFolders}", recentCloudElementsSettings.includeFolders)
    );

/**
 * Connect plan to a topic
 */
export const connectToTopic = (planId, topicId) =>
    post(
        `/restapi/planner/plan/connectToTopic`,
        { CourseId: config.courseId, PlanId: planId, TopicId: topicId },
        headers()
    );

/**
 * Connect plan to a topic with option to move elements to new topic folder
 */
export const connectToTopicV2 = (planId, sourceTopicId, destinationTopicId) =>
    post(
        `/restapi/planner/plan/connectToTopic/v2`,
        { CourseId: config.courseId, PlanId: planId, SourceTopicId: sourceTopicId, DestinationTopicId: destinationTopicId },
        headers()
    );

/**
 * Move any elements that could be moved to new folder when changing topic in plan
 */
export const moveElementsInTopicFolderForPlan = (planId, sourceTopicId, destinationTopicId) =>
    post(
        `/restapi/planner/plan/moveElementsInTopicFolderForPlan`,
        { CourseId: config.courseId, PlanId: planId, SourceTopicId: sourceTopicId, DestinationTopicId: destinationTopicId },
        headers()
    );

/**
 * Remove topic from a plan
 */
export const removeTopic = planId =>
    post(
        `/restapi/planner/plan/removeTopic`,
        { CourseId: config.courseId, PlanId: planId },
        headers()
    );

export const deletePlanElement = (planId, elementId) =>
    post(
        `/restapi/planner/element/deletePlanElement`,
        {
            CourseId: config.courseId,
            PlanId: planId,
            ElementId: elementId,
            DeleteFromCourse: false
        },
        headers()
    );

export function deletePlanElementFromCourse(planId, elementId) {
    return post(
        `/restapi/planner/element/deletePlanElement`,
        {
            CourseId: config.courseId,
            PlanId: planId,
            ElementId: elementId,
            DeleteFromCourse: true
        },
        { headers: { [config.antiForgeryHeaderName]: config.antiForgeryHeaderValue } }
    );
}

export function getCourseResources(elementType, learningToolType, pageIndex, pageSize) {
    const queryParams = [
        `PageIndex=${pageIndex}`,
        `PageSize=${pageSize}`,
        elementType !== undefined && `ElementType=${elementType}`,
        learningToolType !== undefined && `LearningToolType=${learningToolType}`
    ]
        .filter(p => !!p)
        .join("&");

    return get(`/restapi/personal/courses/${config.courseId}/resources/v1?${queryParams}`);
}

export function getCourseFolderResources(
    folderId,
    elementType,
    learningToolType,
    pageIndex,
    pageSize
) {
    const queryParams = [
        `PageIndex=${pageIndex}`,
        `PageSize=${pageSize}`,
        elementType !== undefined && `ElementType=${elementType}`,
        learningToolType !== undefined && `LearningToolType=${learningToolType}`
    ]
        .filter(p => !!p)
        .join("&");

    return get(
        `/restapi/personal/courses/${config.courseId}/folders/${folderId}/resources/v1?${queryParams}`
    );
}

/**
 * Get terms for course
 */
export const getCourseTerms = () =>
    get(`/restapi/terms/current/${config.courseId}/v1`);


/**
 * Update the term for a topic
 */
export const updateTopicTerm = (termId) =>
    put(
        `/restapi/planner/topicTerm/set`,
        { CourseId: config.courseId, TermId: termId, TopicId: config.topicId },
        headers()
    );

/**
 * Temove the term for a topic
 */
export const removeTopicTerm = () =>
    del(
        `/restapi/planner/topicTerm/remove`,
        { CourseId: config.courseId, CourseTermId: 0, TopicId: config.topicId },
        headers()
    );

/**
 * Remove single plan from course (i.e. when cancel a new plan creation)
 */
export const removePlan = planId =>
    post(
        `/restapi/planner/plan/multiple/remove`,
        { CourseId: config.courseId, PlanIds: planId, IsBulkAction: false },
        headers()
    );

/**
 * Remove multiple plans from course
 */
export const removePlans = (planIds, executeForAllPlans = false, topicIds = []) => {
    const requestModel = { courseId: config.courseId, isBulkAction: true };

    if (executeForAllPlans) {
        requestModel.executeForAllPlans = true;
        requestModel.topicIds = topicIds;
    } else {
        requestModel.planIds = planIds;
    }

    return del(`/restapi/planner/plan/multiple/remove/v2`, requestModel, headers());
};

export const setCustomFieldText = (entityId, columnId, data) =>
    post(
        `/restapi/planner/column/updatecustomcolumncontent`,
        {
            courseId: config.courseId,
            columnId: columnId,
            entityId: entityId,
            data: data,
            replaceElementsWithPlaceholders: false,
            skipPlainTextDataTrimming: true
        },
        headers()
    );

export const setTopicLearningObjectives = (topicId, columnId, learningObjectiveIds) =>
    post(
        "/restapi/planner/learningObjective/topic",
        { courseId: config.courseId, topicId, columnId, learningObjectiveIds },
        headers()
    );

export const removeTopicLearningObjective = (topicId, columnId, learningObjectiveId) =>
    del(
        "/restapi/planner/learningObjective/topic",
        { courseId: config.courseId, topicId, columnId, learningObjectiveId },
        headers()
    );

/**
 * Gets status of uploaded files to the plan
 */
export const getPlanFilesUploadStatus = planId =>
    get(
        `/restapi/planner/element/planFilesUploadStatus?courseId=${config.courseId}&planId=${planId}`
    );

export const setElementOrder = (planId, columnId, elementId, elementIds) =>
    post(
        "/restapi/planner/element/movePlanElement",
        {
            courseId: config.courseId,
            sourcePlanId: planId,
            targetPlanId: planId,
            columnId,
            elementId,
            elementIds
        },
        headers()
    );

export const setTopicOrder = topicIds =>
    put("/restapi/planner/topic/reorder", { courseId: config.courseId, topicIds }, headers());

/**
 * Gets courses for a person, with a small set of details. Used for copy plans.
 */
export async function getCourses(pageIndex = 0) {
    const result = await get(`/restapi/personal/courses/basicinfo/v1?PageIndex=${pageIndex}`);

    return result.EntityArray;
}

/**
 * Copy plan(s) from course
 */
export const copyPlans = (
    targetCourseId,
    targetTopicId,
    copyDates,
    copyResourcesAndActivities,
    overwriteAssessmentCriteria,
    plansIds,
    copyWithoutTopic,
    executeForAllPlans = false,
    topicIds = []
) => {
    return post(
        `/restapi/planner/copyPlanner/copyPlans/v2`,
        {
            SourceCourseId: config.courseId,
            targetCourseId,
            targetTopicId,
            copyDates,
            copyResourcesAndActivities,
            overwriteAssessmentCriteria,
            ElementIds: executeForAllPlans ? [] : plansIds,
            copyWithoutTopic,
            executeForAllPlans,
            topicIds
        },
        headers()
    );
};

/**
 * Gets topics name and id for a specific course (used in copy plans)
 */
export const getTopicsForCopyPlans = (
    targetCourseId,
    originalTopicId = 0 // TODO: Change this value when adding the logic to create new topic, 0 = Without topic
) =>
    post(
        `/restapi/planner/copyPlanner/getTopicsForCopyPlans`,
        {
            CourseId: config.courseId,
            TargetCourseId: targetCourseId,
            OriginalTopicId: originalTopicId
        },
        { headers: { [config.antiForgeryHeaderName]: config.antiForgeryHeaderValue } }
    );

/**
 * Get available course event dates.
 */
export const getCalendarAvailableDates = (start, end) =>
    post(
        `/restapi/calendar/courseEventsInTimeFrame`,
        {
            CourseId: config.courseId,
            StartDate: JSON.stringify(start),
            EndDate: JSON.stringify(end)
        },
        headers()
    );

/**
 * Delete topics (move to trash can)
 */
export const deleteTopics = topicIds =>
    post(
        `/restapi/planner/topic/deleteTopics`,
        { CourseId: config.courseId, TopicIds: topicIds },
        headers()
    );

/**
 * Copy topics
 */
export const copyTopics = (
    targetCourseId,
    copyDates,
    copyResourcesAndActivities,
    overwriteAssessmentCriteria,
    topicIds
) =>
    post(
        `/restapi/planner/copyPlanner/copyTopics`,
        {
            SourceCourseId: config.courseId,
            TargetCourseId: targetCourseId,
            TargetTopicId: null,
            CopyDates: copyDates,
            CopyResourcesAndActivities: copyResourcesAndActivities,
            OverwriteAssessmentCriteria: overwriteAssessmentCriteria,
            ElementIds: topicIds
        },
        headers()
    );

/**
 * Set plan order for plans (i.e. under "Without date", "All plans" tabs)
 */
export function dragAndDropPlanInLesson(sourceId, targetId, topicId, after) {
    if (sourceId === targetId) {
        return;
    }

    return post(
        `/restapi/planner/plan/dragAndDrop`,
        {
            sourceId: sourceId,
            targetId: targetId,
            insertAfterTargetItem: after,
            courseId: config.courseId,
            topicId: topicId
        },
        headers()
    );
}

/**
 * Set plan order for a (single) topic after drag and drop
 */
export function dragAndDropPlanInTopic(sourceId, targetId, topicId, after, order) {
    if (sourceId === targetId) {
        return;
    }

    return post(
        `/restapi/planner/plan/dragAndDropInTopic`,
        {
            sourceId,
            targetId,
            insertAfterTargetItem: after,
            courseId: config.courseId,
            topicId,
            plansInTopicOrder: order
        },
        headers()
    );
}

/**
 * Gets the plan Id for plan that is last in order
 */
export const getPlanLastInOrder = () =>
    get(`/restapi/planner/plan/course/${config.courseId}/noTopic/order/last`);

/**
 * Gets the plan Id for plan that is last in order for a topic
 */
export const getPlanLastInOrderTopic = topicId =>
    get(`/restapi/planner/plan/course/${config.courseId}/topic/${topicId}/order/last`);

/**
 * Toggle element activation
 */
export const activatePlanElement = (
    elementId,
    activate,
    isFolder,
    changeActivationForFolderContent
) =>
    put(
        `/restapi/elements/${elementId}/activation/v1`,
        {
            IsFolder: isFolder,
            Activate: activate,
            ChangeActivationForFolderContent: changeActivationForFolderContent
        },
        headers()
    );

/**
 * Gets count of usages of element across plans in a course.
 */
export const getElementUsageCount = elementId =>
    get(`/restapi/planner/element/getElementUsageCount?elementId=${elementId}`);

/**
 * KPI for opening a plan card.
 */
export const measurePlanCardKpi = () =>
    post(`/restapi/personal/course/${config.courseId}/plans/measurePlanCardKpi/v1`, {}, headers());

/**
 * Gets the plan that is pinned.
 */
export const getPinnedPlan = () => get(`/restapi/planner/plan/pinned?courseId=${config.courseId}`);

/**
 * Sets the pinned field for the given plan id.
 */
export const updatePlanPinned = (planId, pinned) =>
    put(
        `/restapi/planner/plan/pinned`,
        {
            CourseId: config.courseId,
            PlanId: planId,
            Pinned: pinned
        },
        { headers: { [config.antiForgeryHeaderName]: config.antiForgeryHeaderValue } }
    );

export const setPlansTimeSpan = (
    planIds,
    startDate,
    stopDate,
    weekNumber,
    weekDays,
    order,
    executeForAllPlans,
    topicIds
) =>
    post(
        `/restapi/planner/planDate/timeSpan/multiple/v2`,
        getSetDateRequestModel(
            planIds,
            startDate,
            stopDate,
            weekNumber,
            weekDays,
            order,
            executeForAllPlans,
            topicIds
        ),
        headers()
    );

export const setPlansWeekAndDay = (
    planIds,
    startDate,
    stopDate,
    weekNumber,
    weekDays,
    order,
    executeForAllPlans,
    topicIds
) =>
    post(
        `/restapi/planner/planDate/weekAndDay/multiple/v2`,
        getSetDateRequestModel(
            planIds,
            startDate,
            stopDate,
            weekNumber,
            weekDays,
            order,
            executeForAllPlans,
            topicIds
        ),
        headers()
    );

export const setPlansTimeSpanEvents = (
    planIds,
    firstPlanEventIds,
    executeForAllPlans = false,
    topicIds = []
) => {
    const requestModel = {
        courseId: config.courseId,
        firstPlanEventIds,
        filterStartDate: "",
        filterStopDate: "",
        isDateColumnDisplayed: true
    };

    if (executeForAllPlans) {
        requestModel.executeForAllPlans = true;
        requestModel.topicIds = topicIds;
    } else {
        requestModel.planIds = planIds;
    }

    return post(`/restapi/planner/planDate/events/multiple/v3`, requestModel, headers());
};
/**
 * Checks if plans has been reordered in topic
 */
export const hasPlansForTopicBeenReordered = topicId =>
    get(
        `/restapi/planner/plan/hasPlansForTopicBeenReordered?courseId=${config.courseId}&topicId=${topicId}`
    );

/**
 * Get plan order within topic
 */
export const getPlanOrderInTopic = topicId =>
    get(`/restapi/planner/topic/planOrder?courseId=${config.courseId}&topicId=${topicId}`);

/**
 * Set plan order within topic
 */
export const setPlanOrderInTopic = (topicId, planOrder) =>
    put(
        `/restapi/planner/topic/planOrder`,
        {
            CourseId: config.courseId,
            TopicId: topicId,
            PlanOrder: planOrder
        },
        headers()
    );

/**
 * Reset plan order within topic
 */
export const resetPlansInTopicOrder = (topicId, plansInTopicOrder) =>
    put(
        `/restapi/planner/plan/resetPlansInTopicOrder`,
        {
            CourseId: config.courseId,
            TopicId: topicId,
            PlansInTopicOrder: plansInTopicOrder
        },
        headers()
    );

/**
 * Copy the current order to the order in topic (e.g. case when switching from old to new planner)
 */
export const copyOrderToOrderInTopic = topicId =>
    put(
        `/restapi/planner/plan/copyOrderToOrderInTopic`,
        {
            CourseId: config.courseId,
            TopicId: topicId
        },
        headers()
    );

/**
 * Stop sharing planner
 */
export const stopSharingPlanner = plannerShareableObjectId =>
    post(
        `/restapi/planner/shareablePlanner/stop`,
        {
            CourseId: config.courseId,
            plannerShareableObjectId: plannerShareableObjectId
        },
        headers()
    );

export const togglePlanElementsActivation = (planId, activate) =>
    put(
        "/restapi/planner/plan/elements/activation",
        {
            courseId: config.courseId,
            planId: planId,
            activateElements: activate
        },
        headers()
    );

export const getTopicsForTableView = (pageIndex = 0) =>
    get(
        `/restapi/course/${config.courseId}/plans/tableview/topics/v1?PageIndex=${pageIndex}&PageSize=${pageSize}`
    );

export const getPlansForTopicInTableView = (courseId, topicId, pageIndex) =>
    get(
        `/restapi/course/${courseId}/plans/tableview/topics/${topicId}/plans/v1?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );

export const getPlansWithoutTopicInTableView = (courseId, pageIndex) =>
    get(
        `/restapi/course/${courseId}/plans/tableview/topics/withoutTopic/plans/v1?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );

export const getTableViewPlans = (topicIds = [], pageIndex = 0) =>
    post(
        `/restapi/course/${config.courseId}/plans/tableview/v1?PageIndex=${pageIndex}&PageSize=${pageSize}`,
        {
            topicIds
        },
        headers()
    );

const getSetDateRequestModel = (
    planIds,
    startDate,
    stopDate,
    weekNumber,
    weekDays,
    order,
    executeForAllPlans,
    topicIds
) => {
    const requestModel = {
        courseId: config.courseId,
        start: startDate,
        stop: stopDate,
        weekNumber: weekNumber,
        weekDays: JSON.stringify(weekDays),
        order
    };

    if (executeForAllPlans) {
        requestModel.executeForAllPlans = true;
        requestModel.topicIds = topicIds;
    } else {
        requestModel.planIds = planIds;
    }

    return requestModel;
};

export const getAllPlansForPrintingTable = topicIds =>
    get(
        `/restapi/personal/course/${config.courseId}/plans/print/table/all/v1?topicIds=${topicIds.join()}`
    );

export const getTopicCurrentPlans = (
    topicId,
    pageIndex = 0,
    pageSize = plansForTopicPageSize,
    planOrder = planSorting.NotSetValue
) =>
    get(
        `/restapi/personal/course/${
            config.courseId
        }/plans/topics/${topicId}/current/v1?${getQueryStringPaging(
            pageIndex,
            pageSize,
            config.childId
        )}&PlanOrder=${planOrder}`
    );

export const getTopicPastPlans = (
    topicId,
    pageIndex = 0,
    pageSize = plansForTopicPageSize,
    planOrder = planSorting.NotSetValue
) =>
    get(
        `/restapi/personal/course/${
            config.courseId
        }/plans/topics/${topicId}/past/v1?${getQueryStringPaging(
            pageIndex,
            pageSize,
            config.childId
        )}&PlanOrder=${planOrder}`
    );

export const getTopicWithoutDatePlans = (
    topicId,
    pageIndex = 0,
    pageSize = plansForTopicPageSize,
    planOrder = planSorting.NotSetValue
) =>
    get(
        `/restapi/personal/course/${
            config.courseId
        }/plans/topics/${topicId}/without-date/v1?${getQueryStringPaging(
            pageIndex,
            pageSize,
            config.childId
        )}&PlanOrder=${planOrder}`
    );

export const getTopicAllPlans = (
    topicId,
    pageIndex = 0,
    pageSize = plansForTopicPageSize,
    planOrder = planSorting.NotSetValue
) =>
    get(
        `/restapi/personal/course/${
            config.courseId
        }/plans/topics/${topicId}/all/v1?${getQueryStringPaging(
            pageIndex,
            pageSize,
            config.childId
        )}&PlanOrder=${planOrder}`
    );

/**
 * Move element to another plan
 */
export const moveElementToPlan = (
    elementId,
    targetPlanId,
    sourcePlanId = config.planId,
    columnId = config.elementColumnId
) =>
    post(
        `/restapi/planner/element/moveElementToPlan`,
        {
            courseId: config.courseId,
            elementId: elementId,
            sourcePlanId: sourcePlanId,
            targetPlanId: targetPlanId,
            columnId: columnId
        },
        headers()
    );

/**
 * Gets all available course template types
 */
export const getAvailabilityOfTemplateTypes = (courseId, topicId) =>
    get(endpoints.getAvailabilityOfTemplateTypes(courseId, topicId));

/**
 * Gets my or other course templates
 */
export const searchForCourses = async (options, paging) =>
    get(endpoints.searchForCourses(options, paging));

/**
 * Gets from publishers or other templates
 */
export const searchForPlannerTemplates = async (options, paging) =>
    get(endpoints.searchForPlannerTemplates(options, paging));

/**
 * Gets topics from course
 */
export const getTopicsFromCourse = (courseId, templateCourseId, paging) =>
    get(endpoints.getTopicsFromCourse(courseId, templateCourseId, paging));

/**
 * Gets topics from shareable planner
 */
export const getTopicsFromShareablePlanner = (courseId, templateCourseId, type) =>
    get(endpoints.getTopicsFromShareablePlanner(courseId, templateCourseId, type));

/**
 * Gets plans in topic from course
 */
export const getPlansInTopicFromCourse = (courseId, templateCourseId, topicId, paging) =>
    get(endpoints.getPlansInTopicFromCourse(courseId, templateCourseId, topicId, paging));

/**
 * Gets resourses from plan.
 * type parameter is of type ElementCategory.
 */
export const getResourcesFromTemplateCourse = (courseId, templateCourseId, topicId, planId, paging, type) =>
    get(endpoints.getResourcesFromTemplateCourse(courseId, templateCourseId, topicId, planId, paging, type)
    );

/**
 * Import resourses
 */
export const importResources = (courseId, importResourcesModel) =>
    post(
        endpoints.importResources(courseId),
        importResourcesModel,
        headers()
    );

/**
 * Save user preference page size.
 */
export const saveUserPreferencePageSize = (courseId, pageSize) =>
    put(
        endpoints.saveUserPreferencePageSize(courseId),
        pageSize,
        headers()
    );
